interface AlertModalButtonsInput {
    cancel?:
        | true
        | string
        | { label?: string; onClick?: () => Promise<void> | void };
    confirm?: string | { label?: string; onClick?: () => Promise<void> | void };
}

const alert = (
    title: string | null,
    message: string,
    buttons?: AlertModalButtonsInput,
) => {
    const alert = useNuxtApp().$alert as (
        title: string | null,
        message: string,
        buttons?: AlertModalButtonsInput,
    ) => void;

    alert(title, message, buttons);
};

export { AlertModalButton, AlertModalButtonsInput, alert };
